export const PAPERTYPES = [
    {
        _id: "5d34b7cd58825a21ac9161c3",
        code: "agreement",
        name: "Agreement",
        description: "",
        language: "en"
    },
    {
        _id: "5d34b7ba58825a21ac9161c2",
        code: "landtitle",
        name: "Landtitle",
        description: "",
        language: "en"
    },
    {
        _id: "5d4c38169a0c6632fcea027d",
        code: "lease",
        name: "Lease",
        description: "",
        language: "en"
    },
    {
        _id: "6051c9f26e7aca0c78967c28",
        code: "rental",
        name: "Rental",
        description: "",
        language: "en"
    }
]

export const PLOTTYPES = [
    {
        _id:"5d34b77858825a21ac9161bf",
        code:"plot",
        name:"Plot",
        description:"",
        language:"en"
    },
    {
        _id:"5d34b79258825a21ac9161c0",
        code:"house",
        name:"House",
        description:"",
        language:"en"
    },
    {
        _id:"5d34b7a658825a21ac9161c1",
        code:"commercial",
        name:"Commercial",
        description:"",
        language:"en"
    },
    {
        _id:"605755935883767bc8494b35",
        code:"agri",
        name:"Agriculture",
        description:"",
        language:"en"
    }
]
export const CURRENCIES = [
    {
        _id:"5d2651de356a7152a0dd9081",
        enabled:"true",
        country:"Europe",
        short:"EUR",
        name:"EUR",
        long:"EURO",
        symbol:"€",
        UGXrate:"4100",
        countryshort:"EUR"
    },
    {
        _id:"5d2651ba356a7152a0dd9080",
        enabled:"false",
        country:"Great Britain",
        short:"GBP",
        name:"GBP",
        long:"British Pound",
        symbol:"?",
        UGXrate:"4800",
        countryshort:"GB"
    },
    {
        _id:"5d2651fd356a7152a0dd9082",
        country:"Uganda",
        enabled:"true",
        short:"UGX",
        name:"UGX",
        long:"Ugandan Shilling",
        symbol:"UGX",
        UGXrate:"1",
        countryshort:"UG"
    },
    {
        _id:"5d264fd49246b552a043f3bc",
        enabled:"true",
        country:"United States",
        short:"USD",
        name:"USD",
        long:"American Dollar",
        symbol:"$",
        UGXrate:"3500",
        countryshort:"USA"
    }]


export const UNITS = [{name:'ft'}]