// Material UI components
import React from 'react';
import { Link } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import CheckShowProp from '../utils/CheckShowProp'

import {
    Item,
    Label,
    Flag,
    Rating
  } from 'semantic-ui-react'

import * as VARIOUS from '../constants/various';
import * as ROUTES from '../constants/routes';

const numeral = require('numeral');


/*

*/
const showDC = (plot)=>{
    let text = ''
    if(typeof plot.district !== 'undefined'){
        text = `${plot.district}`; 
    }
    if(typeof plot.city !== 'undefined'){
        if(text) text = `${text} -`;
        text = `${text} ${plot.city}`;
    }
    return text
}

/*

*/
const ShowTypeLabel = ({type})=>{

    switch(type){
        case 'Plot':
            return <Label icon='square' content={type} />
        case 'House':
            return <Label icon='home' content={type} />
        case 'Commercial':
            return <Label icon='building' content={type} />
        case 'Agriculture':
            return <Label icon='tree' content={type} />
        default:
            return '';
    }
}

/*

*/
const ShowFlagLabel = ({country})=>{
    
    switch(country){
        case 'Uganda':
            return   <Label><Flag name={'ug'}/>{country}</Label> 
        case 'Kenya':
            return   <Label><Flag name={'ke'}/>{country}</Label>             
        case 'Rwanda':
            return   <Label><Flag name={'rw'}/>{country}</Label> 
        case 'Nigeria':
            return   <Label><Flag name={'ng'}/>{country}</Label>             
        case 'Tanzania':
            return   <Label><Flag name={'tz'}/>{country}</Label> 
        case 'South Africa':
            return   <Label><Flag name={'za'}/>{country}</Label>  
        case 'Niger':
            return   <Label><Flag name={'ne'}/>{country}</Label> 
        case 'Somalia':
            return   <Label><Flag name={'so'}/>{country}</Label>             
        case 'Congo':
            return   <Label><Flag name={'cd'}/>{country}</Label> 
        case 'Sudan':
            return   <Label><Flag name={'sd'}/>{country}</Label>             
        case 'Sierra Leone':
            return   <Label><Flag name={'sl'}/>{country}</Label> 
        case 'Senegal':
            return   <Label><Flag name={'sn'}/>{country}</Label> 
        case 'Zambia':
            return   <Label><Flag name={'zm'}/>{country}</Label> 
        case 'Zimbabwe':
            return   <Label><Flag name={'zw'}/>{country}</Label>             
        case 'Yemen':
            return   <Label><Flag name={'ye'}/>{country}</Label> 
        case 'Swaziland':
            return   <Label><Flag name={'sz'}/>{country}</Label>             
        case 'Mozambique':
            return   <Label><Flag name={'mz'}/>{country}</Label> 
        case 'Liberia':
            return   <Label><Flag name={'lr'}/>{country}</Label>                  
        default:
            return ''
    }
}

/*

*/
const ShowContractLabel = ({papertype})=>{
    switch(papertype){
        default:
            return  <Label icon='file' content={papertype} />     
    }
}

class PlotListRow extends React.Component {

    constructor(props){
        super(props);
        this.state={
            star: 0
        }
    }
    // Check if this plot has a star for this users

    calcStar(starred_ids, plot_id){
        // Set the star or unset the star
        (starred_ids.lastIndexOf(plot_id)!==-1)?
            this.setState({star:1})
        :
            this.setState({star:0})
    }

    componentDidMount(){
        this.calcStar(this.props.starred_ids, this.props.plot._id)
    }

    componentDidUpdate(prevProps) {
        if(this.props.starred_ids.length !== prevProps.starred_ids.length) 
        {
            this.calcStar(this.props.starred_ids, this.props.plot._id)
        }
      }
        
    render(){
        const {plot} = this.props;
		return (
            <Item>
                <Item.Image as={Link} to={`${ROUTES.SHOWPLOT}/${plot._id}`} wrapped size='medium' 
                    src={   (plot.images.length === 0 )
                            ? VARIOUS.DUMMY_PLOT_IMG_640
                            : plot.images[0].url 
                        } 
                />
                <Item.Content>
                    <Item.Header as={Link} to={`/showplot/${plot._id}`}>
                        {plot.name}
                    </Item.Header>
                    {(this.props.isLoggedIn)
                        ?<Rating onClick={()=>this.props.handleStoreStars(plot._id)} maxRating={1} rating={this.state.star} icon='star' size='huge' />
                        :''
                    }
                    <Item.Meta className='plot-list-type'>{showDC(plot)}</Item.Meta>
                    <Item.Meta className='plot-list-header'>Description</Item.Meta>
                    <Item.Description >
                        <ShowMoreText
                                lines={3}
                                more=' Show more'
                                less=' [-]'
                                expanded={false}
                                >
                            {(plot.description)? plot.description : 'N/A'}
                        </ShowMoreText>
                    </Item.Description>
                    <Item.Extra className='plot-list-price'>Price: {plot.currency}&nbsp;{numeral(plot.price).format('0,0')}{(plot.sold)?' (SOLD)':''}</Item.Extra>
                    <Item.Extra>
                        <CheckShowProp item={plot.country}>    
                            <ShowFlagLabel country={plot.country}/>
                        </CheckShowProp>
                        <CheckShowProp item={plot.type}>
                            <ShowTypeLabel type={plot.type}/>
                        </CheckShowProp>
                        <CheckShowProp item={plot.papertype}>
                            <ShowContractLabel papertype={plot.papertype} />
                        </CheckShowProp>
                    </Item.Extra>   
                </Item.Content>
            </Item>  
        )
    }
}

export default PlotListRow;