import React from 'react';
import { withRouter } from "react-router";
import Datalist from './Datalist';

import {
    Input,
    Button,
    Dropdown,
    Form,
    Segment,
    Header,
  } from 'semantic-ui-react'

const numeral = require('numeral');

class SearchPanel extends React.Component{

    constructor(props){
        super(props);
        //TODO: create the lists globally and with endpoint
        this.state={
            searchterms:{
                ...this.props.searchterms,
                unit:'ft2'
            },
            fieldChanged: false,
            loading: false,
            plottypes:[ 
                        {key:'All',text:'All',value:'All'},
                        {key:'Plot',text:'Plot',value:'Plot'},
                        {key:'House',text:'House',value:'House'},
                        {key:'Commercial',text:'Commercial',value:'Commercial'},
                        {key:'Agriculture',text:'Agriculture',value:'Agriculture'}],
            papertypes:[ 
                        {key:'All',text:'All',value:'All'},
                        {key:'Landtitle',text:'Landtitle',value:'Landtitle'},
                        {key:'Rental',text:'Rental',value:'Rental'},
                        {key:'Lease',text:'Lease',value:'Lease'},
                        {key:'Agreement',text:'Agreement',value:'Agreement'},
                        ], 
            currencies:[ {key:'UGX',text:'UGX',value:'UGX'},
                        {key:'EUR',text:'EUR',value:'EUR'},
                       {key:'USD',text:'USD',value:'USD'},
                       ],              
            units:[ {key:'ft',text:'ft2',value:'ft2'}]
        }
    }

    // Handle the submit button press
    handleSubmit = (event)=>{
        event.preventDefault();
        this.props.fireSubmit(this.state.searchterms);
    }

    // Handle the local state and global state update
    // For numerals and keeping in mind the formatting
    handleChangeNumeral = (event) => {
		this.setState((prevState)=>( 	
			{...prevState,searchterms:{
                ...prevState.searchterms,
						[event.target.name]: numeral(event.target.value).format('0')}}
		),
		()=>{
			this.setState({fieldChanged: true});
            this.props.globalSetSearchTerms(this.state.searchterms);
		}
		);
	};


    handleDropdownChange = (event,{name,value}) => {
        this.setState(
                (prevState)=>({...prevState,searchterms:{...prevState.searchterms,[name]:value}}),
                ()=>{
                    this.setState({fieldChanged: true});
                    this.props.globalSetSearchTerms(this.state.searchterms);
                }
        )
	};
    // Handle the local state and global state update 
    // for the non numeral fields
    handleChange = (event) => {
		this.setState((prevState)=>( 	
			{...prevState,searchterms:{
                ...prevState.searchterms,
						[event.target.name]: event.target.value}}
		),
		()=>{
			this.setState({fieldChanged: true});
            this.props.globalSetSearchTerms(this.state.searchterms);
		}
		);
	};

    render(){
        return (
            <>
            <Segment raised>
                <Header as='h3'>
					Search criteria
				</Header>
                <Form>

                <Form.Group widths='equal' unstackable>
                <Form.Input 
                    fluid
                    name='minprice'
                    label='Min. price'
                    value = {numeral(this.state.searchterms.minprice).format('0,0')}
                    placeholder='Minimum search price'
                    onChange={this.handleChangeNumeral}
                />
                <Form.Input 
                    fluid
                    name='maxprice'
                    label='Max. price'
                    value = {numeral(this.state.searchterms.maxprice).format('0,0')}
                    placeholder='Maximum search price'
                    onChange={this.handleChangeNumeral}
                /> 

                <Form.Field width={5}>
                <Dropdown.Item
                text='Currency'/>
                <Dropdown
                    search
                    selection
                    fluid
                    name='currency'
                    label='Currency'
                    size='small'
                    placeholder='Choose currency'
                    value={this.state.searchterms.currency}
                    options={this.state.currencies}
                    onChange={this.handleDropdownChange}
                    />    
                </Form.Field>
                </Form.Group>
                <Form.Group widths='equal' >
                <Form.Field
                        control={Input}
                        label='Region'
                        name='region'
                        list='regions' 
                        placeholder='Choose region...' 
                        size='small'
                        value={this.state.searchterms.region}
                        onChange={this.handleChange}
                    >
                    <input />
                    <Datalist listoptions={this.props.regions||[]} id_name='regions'/>
                    <datalist/>
                </Form.Field>
                <Form.Field
                        control={Input}
                        label='District'
                        name='district'
                        list='districts' 
                        placeholder='Choose district...' 
                        size='small'
                        value={this.state.searchterms.district}
                        onChange={this.handleChange}
                    >
                    <input />
                    <Datalist listoptions={this.props.districts||[]} id_name='districts'/>
                    <datalist/>
                </Form.Field>
                <Form.Field
                        control={Input}
                        label='City'
                        name='city'
                        list='cities' 
                        placeholder='Choose city...' 
                        size='small'
                        value={this.state.searchterms.city}
                        onChange={this.handleChange}
                    >
                    <input />
                    <Datalist listoptions={this.props.cities||[]} id_name='cities'/>
                    <datalist/>
                </Form.Field>
                </Form.Group>
                <Form.Group widths='equal' >
                <Form.Field>
                <Dropdown.Item
                text='Property type'/>
                <Dropdown
                    search
                    selection
                    fluid
                    name='type'
                    label='Property type'
                    size='small'
                    placeholder='Leave empty for all'
                    value={this.state.searchterms.type}
                    options={this.state.plottypes}
                    onChange={this.handleDropdownChange}
                    />
                </Form.Field>
                <Form.Field>
                <Dropdown.Item
                text='Paper type'/>
                <Dropdown
                    search
                    selection
                    fluid
                    name='papertype'
                    label='Contract type'
                    size='small'
                    placeholder='Leave empty for all'
                    value={this.state.searchterms.papertype}
                    options={this.state.papertypes}
                    onChange={this.handleDropdownChange}
                    />
                </Form.Field>
                </Form.Group>
                <Form.Group widths='equal' unstackable>
                <Form.Input 
                    fluid
                    name='minarea'
                    label='Min. area (ft)'
                    value = {numeral(this.state.searchterms.minarea).format('0,0')}
                    placeholder='Minimum area'
                    onChange={this.handleChangeNumeral}
                />
                <Form.Input 
                    fluid
                    name='maxarea'
                    label='Max. area (ft)'
                    value = {numeral(this.state.searchterms.maxarea).format('0,0')}
                    placeholder='Maximum area'
                    onChange={this.handleChangeNumeral}
                /> 
                <Form.Field width={5}>
                <Dropdown.Item
                text='Unit'/>
                <Dropdown
                    search
                    selection
                    fluid
                    name='unit'
                    label='Unit'
                    size='small'
                    placeholder='Unit'
                    value={this.state.searchterms.unit}
                    options={this.state.units}
                    onChange={this.handleDropdownChange}
                    /> 
                </Form.Field>
                </Form.Group>
                <br/>
                <Form.Group inline floated='right'>
                    <Form.Field 
                        color='violet' 
                        control={Button}  
                        icon='search' 
                        labelPosition='left'
                        content='Search'   
                        floated='right'
                        onClick={this.handleSubmit}               
                    />
                </Form.Group>
                <br/>
                </Form>
            </Segment>
            </>
        )
    }

}

export default withRouter(SearchPanel);