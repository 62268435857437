// Material UI components
import React from 'react';

const Datalist = ({listoptions,id_name}) => {

    return (
        <datalist id={id_name}>
            {
                listoptions.map( opt => (<option key={opt._id} name={opt.name}>{opt.name}</option>))
            }
        </datalist>
    )
}

export default Datalist;

