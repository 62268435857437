import React from "react";

import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Twitter from "./Twitter";
import Pinterest from "./Pinterest";

const Icon = props => {
  switch (props.name) {
    case "facebook":
      return <Facebook {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "pinterest":
      return <Pinterest {...props} />;
    default:
      return;
  }
};

export default Icon;
