import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Container,
  Advertisement
} from 'semantic-ui-react';


import './App.css';

// Global constants
import * as ROUTES from './constants/routes';
import * as GLOBS from './constants/plot_constants';

// State and globals
import brokerGlob from './globals/brokerGlob.js';
import subscriptionGlob from './globals/subscriptionGlob.js';
import searchtermsGlob from './globals/searchtermsGlob.js';


// Pages
import LoginPage from './pages/LoginPage';
import LoginFBPage from './pages/LoginFBPage';


import HomePage from './pages/HomePage';
import ShowPlotsPage from './pages/ShowPlotsPage';

// Components
import ErrorBoundary from './components/ErrorBoundary';
import FixedHeader from './components/FixedHeader';
import Footer from './components/Footer';
import ScrollToTop from './utils/ScrollToTop';

import API from './utils/api';

require('dotenv').config();

const PricingPage = lazy(()=>import('./pages/PricingPage'));
const TCPage = lazy(()=>import('./pages/TCPage'));
const PrivacyPage = lazy(()=>import('./pages/PrivacyPage'));
const HelpPage = lazy(()=>import('./pages/HelpPage'));
const BuildingPage = lazy(()=>import('./pages/BuildingPage'));
const HotelsPage = lazy(()=>import('./pages/HotelsPage'));
const AboutPage = lazy(()=>import('./pages/AboutPage'));
const AdvertisePage = lazy(()=>import('./pages/AdvertisePage'));
const AgentsPage = lazy(()=>import('./pages/AgentsPage'));
const ContactPage = lazy(()=>import('./pages/ContactPage'));
const NotFoundPage = lazy(()=>import('./pages/NotFoundPage'));

const SinglePlotPage = lazy(()=>import('./pages/SinglePlotPage'));
const AddPlotPage = lazy(()=>import('./pages/AddPlotPage'));
const EditPlotPage = lazy(()=>import('./pages/EditPlotPage'));

const SignUpPage = lazy(()=>import('./pages/SignUpPage'));
const ShowStarredPlotsPage = lazy(()=>import('./pages/ShowStarredPlotsPage'));
const ShowBrokerPlotsPage = lazy(()=>import('./pages/ShowBrokerPlotsPage'));

const ProfilePage = lazy(()=>import('./pages/ProfilePage'));
const LogoutPage = lazy(()=>import('./pages/LogoutPage'));

class App extends React.Component {

  constructor(props){
    super(props);

    ///////////////////////////////////////////////////////////////////////
    /// GLOBAL STATE
    ///////////////////////////////////////////////////////////////////////
    this.state = {
      // Alle user information including broker info for the user
      user:{
        // Is a user logged in?
        isLoggedIn: false,
        // The AFRI-TOKEN to put in the header for authenication
        afritoken:'',
        // Database Id
        id:null,
        // Firebase uid for user
        uid: null,
        // Is the user an active vistor if not he cannot loggin
        visitor_active:true,
        broker_active:false,
        email:'',
        starred_ids:[],
        avatar_url:'people-avatars-vector-free-icon-set-35.png',
        // Broker information; is filled when the user is also part of a broker
        broker: brokerGlob,
        // The subscription information related to the user
        // Nr of props he can post, 
        // Nr of images he can post
        // when the subscription initially started
        // when the subscription was last renewed
        subscription:subscriptionGlob,
        // Record when the user was last logged in
        previous_logged_in:'',
        // Keep the search terms of the user
        saved_search_terms:{}
      },
      plots:[],         // Store all plots to show --> TODO
      own_plots:[],     // Store all plots for the broker that is logged into show --> TODO
      starred_plots:[], // Starred plots: the array with plot information realted to the user's starred_ids
      /////////////////////////////////////////////////////////////
      //  GLOBAL STATIC INFORMATION
      /////////////////////////////////////////////////////////////
      // All districts 
      districts:[],
      // All regions 
      regions:[],
      // All cities 
      cities:[],
      // All currencies 
      currencies:GLOBS.CURRENCIES,
      // All papertypes 
      papertypes:GLOBS.PAPERTYPES,
      // All units 
      // TODO:  get the units from endpoint
      units:GLOBS.UNITS,
      // All plottypes 
      plottypes:GLOBS.PLOTTYPES,
      /////////////////////////////////////////////////////////////
      // The search terms of  the current visitor filled in 
      // (and Some defualt values)
      /////////////////////////////////////////////////////////////
      searchterms:searchtermsGlob
    }
  }

  ///////////////////////////////////////////////////////////////////
  // Get the user data from the server
  // Get broker data if user is also broker
	// Store user data in local state
  ///////////////////////////////////////////////////////////////////
	getUserData = (token) => {
		API.get(ROUTES.USER_ME,{
			headers: {'AFRI-TOKEN': token}
		})
		.then(res => {
		  const user = res.data;
		  this.setState((prevState)=>(
          {user:{...prevState.user,...user, isLoggedIn:true}}
      )) 

      if(this.state.user?.broker_id){
        API.get(ROUTES.BROKER+'/'+this.state.user.broker_id,{
          headers: {'AFRI-TOKEN': token}
        })
        .then(res =>{
          const broker = res.data;
          this.setState((prevState)=>(
            {user:{...prevState.user,broker:{ ...broker}}}
        ))
      }
      )
    }
		})
    .catch((error) =>{
			console.log('GET user/me: ',error);
		});
    


	}

  ///////////////////////////////////////////////////////////////////
  // Set user object data (@data) in the global the state 
  // store the Afritoken in the localstorage
  // TODO: merge Login and Logout methods
  ////////////////////////////////////////////////////////////////////
    setUserLogin = (data) =>{
      //TODO: get user info
      // Set global state 
      this.setState((prevState)=>(
        {user:{...prevState.user,...data, isLoggedIn:true}}
    )); 
    localStorage.setItem('afritoken', data.afritoken);
    localStorage.getItem('afritoken');  
  }

  ///////////////////////////////////////////////////////////////////
  // Logout user
  // set the state 
  // Erase the Afritoken from localstorage
  ///////////////////////////////////////////////////////////////////
    setUserLogout = (data) =>{
      this.setState((prevState)=>(
        {user:{...prevState.user,...data, isLoggedIn:false}}
    )); 
    localStorage.removeItem('afritoken', '');
  }

  ////////////////////////////////////////////////////////////////////
  //   Store the plots in global state
  ////////////////////////////////////////////////////////////////////
  storePlots = (plots) =>{
    this.setState(plots);
  }

  ///////////////////////////////////
  // Store starred_ids
  ///////////////////////////////////
  handleStoreStars = (currentplot) => {
		let newArr = [];
  		
		// If the plot is not in starred list add it
		if(this.state.user.starred_ids.lastIndexOf(currentplot)===-1){
			newArr = [...this.state.user.starred_ids, currentplot]
		}
		// If the plot is in starred list (localstate) create a new array and assign it to the local state
		else{
			 newArr =  this.state.user.starred_ids.filter((id)=>{
				return (id !== currentplot)
			});
  
		}
		
    this.setState((prevState)=>({...prevState,
      user:{...prevState.user,
            starred_ids:[...newArr]}}
    ));

    API.put(ROUTES.USERSTARRED,{
			starred_ids:[...newArr]
		},{
			headers: {'AFRI-TOKEN': this.state.user.afritoken}
		}); 
	}

  ////////////////////////////////////////////////////////////
  // Store the star ids
  ////////////////////////////////////////////////////////////
  storeStars = (starred_ids) => {
    //TODO: API call to edit stored stars for the user
    this.setState((prevState)=>({...prevState,
                                  user:{...prevState.user,
                                        starred_ids:[...starred_ids]}}
                                ));
  }
  /////////////////////////////////////////////////////////////
  // Store the searchterms 
  /////////////////////////////////////////////////////////////
  globalSetSearchTerms = (searchterms,only=false) => {
    if(only){
     this.setState((prevState)=>({...prevState,searchterms:{...searchterms}}))
    }
    else
    {
      this.setState((prevState)=>({...prevState,searchterms:{...prevState.searchterms,...searchterms}}))
    }
  }

  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
  getRegions = () => { 
    API.get(ROUTES.REGIONS)
    .then(res => {
      const regions = res.data;
      this.setState({ regions });      
    })
  }

  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
  getDistricts = () => {
    API.get(ROUTES.DISTRICTS)
    .then(res => {
      const districts = res.data;
      this.setState({ districts });
      return districts
    });
  }

  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
  getCities = () =>{
    API.get(ROUTES.CITIES)
    .then(res => {
      const cities = res.data;
      this.setState({ cities });
      return cities
    });
  }

  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
   getPlottypes = ()=>{
    API.get(ROUTES.PLOTTYPES)
    .then(res => {
      const plottypes = res.data;
      this.setState(
                    ()=>({ plottypes }),
                    ()=>{localStorage.setItem('plottypes', {...this.state.plottypes})}
                    );
    });
   }

  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
   getPapertypes = () => {
    API.get(ROUTES.PAPERTYPES)
    .then(res => {
      const papertypes = res.data;
      this.setState(
                    ()=>({ papertypes }), 
                    ()=>{localStorage.setItem('papertypes', {...this.state.papertypes})}
                    );
    });
  }
  
  //////////////////////////////////////////////////////////////
  //
  //////////////////////////////////////////////////////////////
  getCurrencies = () => {
    API.get(ROUTES.CURRENCIES)
    .then(res => {
      const currencies = res.data;
      this.setState(()=>({ currencies }),
                    ()=>{localStorage.setItem('currencies', {...this.state.currencies})}
                    );
    });
  }
  ////////////////////////////////////////////////////////////
  //
  ////////////////////////////////////////////////////////////
  componentDidMount(){

      // TODO: create a function to do the below 
      // TODO: create a local storage structure with the date as the top level
      // TODO: this way we can easily see if we want to refresh the static data 
      // TODO: Create a load from local storage 
      
      //const apiversion = getAPIversion();
      //const ls_siteversion = localStorage.getItem("siteversion");
      //const ls_date = localStorage.getItem("siteversion");
      //const ls_regions = localStorage.getItem("regions");;
      //const ls_districts = localStorage.getItem("districts");;
      //const ls_cities = localStorage.getItem("cities");;
      ////////////////////////////////////////////////////////
      //   Regions/District/City Static Values
      ////////////////////////////////////////////////////////

      // Get all regions, get districts, get cities
      this.getDistricts()
      this.getRegions()
      this.getCities()

      
      ////////////////////////////////////////////////////////
      //   UI Static Values
      ////////////////////////////////////////////////////////
      // Get all plottypes
      // Get all papertypes
      // Get all currencies

      /////////////////////////////////////////////////////////
      // Get token from local storage
      // Get user info from server
      // Set state to logged in when there is a token
      /////////////////////////////////////////////////////////
      const token = localStorage.getItem('afritoken');   

      if(token && token.length > 0){
        this.getUserData(token);

        const isLoggedIn = (this.state.user._id)?true:false;
        this.setState((prevState)=>(
            { 
              user:{...prevState.user,afritoken:token, isLoggedIn:isLoggedIn}
            }
          )
        )
      }

      /////////////////////////////////////////////////////////
      // Localstorage
      // GetSet: Currencies
      // GetSet: Disitricts
      // GetSet: Regions
      // GetSet: Searchterms
      // GetSet: User (parts)  
      /////////////////////////////////////////////////////////
      


    }

  render(){
    const { districts, 
            user, 
            searchterms, 
            regions, 
            cities, 
            currencies, 
            plottypes, 
            papertypes,
          } = this.state;
          
      const { starred_ids,
              isLoggedIn
            } = this.state.user;

    return (
      <>
        <Suspense fallback='<div>...Loading</div>'>
          <ErrorBoundary>
          <Router>
          <ScrollToTop>
            <FixedHeader user={user}/>
            <Container style={{ marginTop: '3em' }}>
                <Switch>
                <Route exact path={ROUTES.HOME} 
                    render={(props) => <HomePage 
                      searchterms={searchterms} 
                      globalSetSearchTerms={this.globalSetSearchTerms}  
                      {...props} /> }
                  />  
                <Route exact path='/loginfb' 
                    render={(props) => <LoginFBPage 
                         {...props} /> }
                  /> 
                <Route exact path={ROUTES.LOGIN} 
                    render={(props) => <LoginPage 
                        setUserLogin={this.setUserLogin} 
                        getUserData={this.getUserData} 
                        user={user} {...props} /> }
                  />

                  {(user.isLoggedIn)?
                  <>
                    <Route exact path={ROUTES.PROFILE} 
                      render={(props) => <ProfilePage 
                                          afritoken={this.state.user.afritoken} 
                                          user={user} {...props} /> }
                    />
                    <Route exact path={ROUTES.OWNPLOTS_ID} 
                    render={(props) => <ShowBrokerPlotsPage
                                          storePlots={this.storePlots}
                                          user={user}
                                          {...props} /> }
                  />
                  <Route exact path={ROUTES.STARREDPLOTS} 
                        render={(props) => <ShowStarredPlotsPage
                                            handleStoreStars={this.handleStoreStars}
                                            starred_ids={starred_ids}  
                                            isLoggedIn={this.state.user.isLoggedIn}
                                            {...props} /> }
                      />
                  <Route exact path={ROUTES.ADDPLOT} 
                          render={(props) => <AddPlotPage 
                                          afritoken={this.state.user.afritoken} 
                                          districts={districts} 
                                          cities={cities}
                                          currencies={currencies}
                                          papertypes={papertypes}
                                          plottypes={plottypes}
                                          regions={regions}
                                          globalSetSearchTerms={this.globalSetSearchTerms} 
                                          getRegions={this.getRegions}
                                          getCities={this.getCities}
                                          getDistricts={this.getDistricts}
                                          user={user} {...props} /> }
                      />
                      <Route exact path={ROUTES.EDITPLOT_ID} 
                          render={(props) => <EditPlotPage
                                          afritoken={this.state.user.afritoken} 
                                          districts={districts} 
                                          cities={cities}
                                          papertypes={papertypes}
                                          plottypes={plottypes}
                                          regions={regions}
                                          globalSetSearchTerms={this.globalSetSearchTerms} 
                                          getRegions={this.getRegions}
                                          getCities={this.getCities}
                                          getDistricts={this.getDistricts}
                                          user={user} {...props} /> }
                      />
                  </>
                  :
                      <Route exact path={ROUTES.SIGNUP}
                          render={(props) => <SignUpPage user={user} {...props} /> }
                      />
                      
                    } 
                    <Route exact path={ROUTES.SHOWPLOT_ID} 
                          render={(props) => <SinglePlotPage {...props} /> }
                      />
                      <Route exact path={ROUTES.SHOWPLOTS} 
                          render={(props) => <ShowPlotsPage
                                          storePlots={this.storePlots}
                                          globalSetSearchTerms={this.globalSetSearchTerms} 
                                          handleStoreStars={this.handleStoreStars}
                                          getRegions={this.getRegions}
                                          getCities={this.getCities}
                                          getDistricts={this.getDistricts}
                                          searchterms={searchterms}  
                                          districts={districts}
                                          regions={regions}
                                          cities={cities}
                                          papertypes={papertypes}
                                          currencies={currencies}
                                          plottypes={plottypes}
                                          starred_ids={starred_ids}
                                          isLoggedIn={isLoggedIn}
                                          {...props} /> }
                      />
                      <Route exact path={ROUTES.LOGOUT}
                          render={(props) => <LogoutPage setUserLogout={this.setUserLogout} user={user} {...props} /> }
                      /> 
                      <Route exact path={ROUTES.PRIVACY} component={PrivacyPage}/>
                      <Route exact path={ROUTES.TC} component={TCPage}/>
                      <Route exact path={ROUTES.PRICING} component={PricingPage}/>
                      <Route exact path={ROUTES.ABOUT} component={AboutPage}/>
                      <Route exact path={ROUTES.BUILDING} component={BuildingPage}/>
                      <Route exact path={ROUTES.HELP} component={HelpPage}/>
                      <Route exact path={ROUTES.HOTELS} component={HotelsPage}/>
                      <Route exact path={ROUTES.ADVERTISE} component={AdvertisePage}/>
                      <Route exact path={ROUTES.AGENTS} component={AgentsPage}/>
                      <Route exact path={ROUTES.CONTACT} component={ContactPage}/>
                    <Route component={NotFoundPage} />
                  </Switch>
                  <Advertisement unit='mobile leaderboard' test='Banner' />
                </Container>
              <Footer/>
            </ScrollToTop>
          </Router>
          </ErrorBoundary>  
          </Suspense>

      </>
    );
  }
}
export default App;