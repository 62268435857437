export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const PROFILE = '/profile';
export const OWNPLOTS_ID = '/ownplots/:id';
export const OWNPLOTS = '/ownplots';
export const SHOWPLOTS = '/showplots';
export const ADDPLOT = '/addplot';
export const STARREDPLOTS = '/starredplots';
export const NON_FOUND = '/not-found';
export const EDITPLOT_ID = '/editplot/:id';
export const EDITPLOT = '/editplot';
export const SIGNUP = '/signup';
export const SHOWPLOT_ID = '/showplot/:id';
export const SHOWPLOT = '/showplot';

// API URLS
export const REGIONS = '/regions';
export const DISTRICTS = '/districts';
export const CITIES = '/cities';
export const PLOTTYPES = '/plottypes';
export const PAPERTYPES = '/papertypes';
export const CURRENCIES = '/currencies';
export const USER = '/user';
export const BROKER = '/broker';
export const USER_ME = '/user/me';
export const USER_BROKER= '/user/broker';

export const USERSTARRED = '/user/starred';
export const PLOT_STARRED = '/plot/starred';
export const PLOTS = '/plots';
export const PLOT = '/plot';
export const UPLOAD = '/upload';




//STATIC ROUTES
export const HOTELS = '/hotels';
export const TC = '/tc';
export const PRIVACY = '/privacy';
export const ABOUT = '/about';
export const ADVERTISE = '/advertise';
export const AGENTS = '/agents';
export const BUILDING = '/building';
export const HELP = '/help';
export const CONTACT = '/contact';
export const PRICING = '/pricing';


// SOCIAL MEDIA
export const FACEBOOK_AP = 'https://www.facebook.com/afriplots';
export const TWITTER_AP = 'https://twitter.com/afriplots';
export const INSTAGRAM_AP = 'https://www.instagram.com/afriplots/';
export const PINTAREST_AP = 'https://www.pinterest.com/afriplots/';
