const searchtermGlob = {
    country:'Uganda',
    currency: 'UGX',
    minprice: 5000000,
    maxprice: 50000000,
    district: '',
    city:'',
    location:'',
    type: '',
    papertype: '',
    houseprops:{
      wall:'',
      water:'',
      watertank:'',
      gate:'',
      guesthouse:''
    }
} 

export default searchtermGlob;