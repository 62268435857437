// Material UI components
import React, { Component } from 'react';
import {
    Container,
    Segment,
  } from 'semantic-ui-react'

import HomeSearchPanel from '../components/HomeSearchPanel';

class HomePage extends Component {

  constructor(props){
    super(props);
    document.title = 'Afriplots';
  }


	render() {
		return (
        <Container text style={{ marginTop: '5em' }}>
          <Segment raised className=''>
            <HomeSearchPanel
                  searchterms={this.props.searchterms} 
                  globalSetSearchTerms={this.props.globalSetSearchTerms}/>
          </Segment>

      </Container>
		);
	}
}

export default HomePage;