import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Divider,
    Grid,
    Header,
    Image,
    List,
    Segment,
  } from 'semantic-ui-react'

import Icon from "../assets/icon";
import * as ROUTES from '../constants/routes';

class Footer extends React.Component{

    render(){
        return (
        <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '2em 0em' }}>
            <Container textAlign='center'>
                <Grid divided inverted stackable>
                <Grid.Column width={3}>
                    <Header inverted as='h4' content='Support' />
                    <List link inverted>
                    <List.Item as={Link} to={ROUTES.CONTACT}>Contact</List.Item>
                    <List.Item as={Link} to={ROUTES.LOGIN}>Log in</List.Item>
                    <List.Item as={Link} to={ROUTES.HELP}>Help</List.Item>
                    <List.Item as={Link} to={ROUTES.ABOUT}>About</List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Header inverted as='h4' content='Services' />
                    <List link inverted>
                    <List.Item as={Link} to={ROUTES.ADVERTISE}>Advertisement</List.Item>
                    <List.Item as={Link} to={ROUTES.HOTELS}>Hotels</List.Item>
                    <List.Item as={Link} to={ROUTES.AGENTS}>Agents</List.Item>
                    <List.Item as={Link} to={ROUTES.BUILDING}>Building</List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Header inverted as='h4' content='Plots on Afriplots' />
                    <List link inverted>
                    <List.Item as={Link} to={ROUTES.SIGNUP}>Register</List.Item>
                    <List.Item as={Link} to={ROUTES.PRICING}>Pricing</List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Header inverted as='h4' content='Afriplots, your real-estate starting point' />
                    <p>
                        Looking for anything real-estate, Afriplots connects buyers and sellers!<br/>
                        Let us connect! 
                    </p>
                    <List horizontal inverted divided link size='small'>
                        <List.Item as='a' href={ROUTES.FACEBOOK_AP}>
                            <Icon name="facebook" width={30} fill={'#6435c9'} />
                        </List.Item>
                        <List.Item as='a' href={ROUTES.PINTAREST_AP}>
                            <Icon name="pinterest" width={30} fill={'#6435c9'} />
                        </List.Item>
                        <List.Item as='a' href={ROUTES.INSTAGRAM_AP}>
                            <Icon name="instagram" width={30} fill={'#6435c9'} />
                        </List.Item>
                        <List.Item as='a' href={ROUTES.TWITTER_AP}>
                            <Icon name="twitter" width={30} fill={'#6435c9'} />
                        </List.Item>
                    </List>
                </Grid.Column>
                </Grid>

                <Divider inverted section />

                <Image centered size='small' src='https://afriplots.com/images/afriplots-logo-white.png' />
                <List horizontal inverted divided link size='small'>
                    <List.Item as={Link} to={ROUTES.TC}>Terms and Conditions</List.Item>
                    <List.Item as={Link} to={ROUTES.PRIVACY}>Privacy Policy</List.Item>
                </List>
                <List>
                    <List.Item>&copy; Copyright 2021 - Achterhook (U) Ltd</List.Item>
                </List>
            </Container>
        </Segment>
        )
    }
}

export default Footer;