const brokerGlob = {
    //broker:false,
    name:'',
    address:'',
    address2:'',
    city:'',
    country:'',
    email:'',
    mobile_1:'',
    mobile_2:'',
    url:'',
    facebook:'',
    instagram:'',
    linkedin:'',
    description:'',
    activated_date:''
  }

export default brokerGlob;