import React from 'react'
import { Form, Button, Input, Label } from 'semantic-ui-react';
import { withRouter } from "react-router";
import * as ROUTES from '../constants/routes';

const numeral = require('numeral');


class HomeSearchPanel extends React.Component {

    constructor(props){
        super(props);
        this.state={
            searchterms:{
                    ...this.props.searchterms
            },
            fieldChanged: false
        }
    }


    handleSubmit = (event)=>{
        event.preventDefault();
		this.setState({ loading: true });
        this.handleTypeChange()
        const {minprice, maxprice, type, currency} = this.state.searchterms
        this.props.globalSetSearchTerms({minprice, maxprice, type, currency},true);

        this.props.history.push({
            pathname: ROUTES.SHOWPLOTS,
            state: { searchterms: {minprice, maxprice, type,currency} }
          })
    }

    handleTypeChange =  (event) => {
        console.log()
		this.setState((prevState)=>( 	
			{...prevState,searchterms:{
                ...prevState.searchterms,
						type: event.target.value}}
		),
		()=>{
			this.setState({fieldChanged: true});
            this.props.globalSetSearchTerms({...this.state.searchterms,type:event.target.value});
		}
		);
	};

	handleChange = (event) => {
		this.setState((prevState)=>( 	
			{...prevState,searchterms:{
                ...prevState.searchterms,
						[event.target.name]: numeral(event.target.value).format('0')}}
		),
		()=>{
			this.setState({fieldChanged: true});
            this.props.globalSetSearchTerms({...this.state.searchterms,type:this.props.type});

		}
		);
	};

    render(){

        return   (
            <>    
            <Form>
                <Form.Group width='equal'>
                    <Form.Field
                        control={Button}
                        name='type'
                        value=''
                        content='All'
                        color={(this.state.searchterms.type==='')?'violet':'grey'}
                        onClick={this.handleTypeChange}
                        />
                    <br/>
                    <Form.Field
                        control={Button}
                        name='type'
                        value='Plot'
                        content='Plots'
                        color={(this.state.searchterms.type==='Plot')?'violet':'grey'}
                        onClick={this.handleTypeChange}

                        />
                    <br/>
                    <Form.Field
                        control={Button}
                        name='type'
                        value='House'
                        content='Houses'
                        color={(this.state.searchterms.type==='House')?'violet':'grey'}
                        onClick={this.handleTypeChange}


                        />             
                    <br/>
                    <Form.Field
                        control={Button}
                        name='type'
                        value='Commercial'
                        content='Commercial'
                        color={(this.state.searchterms.type==='Commercial')?'violet':'grey'}
                        onClick={this.handleTypeChange}


                        />           
                    <br/>
                    <Form.Field
                        control={Button}
                        name='type'
                        value='Agriculture'
                        content='Agriculture'
                        color={(this.state.searchterms.type==='Agriculture')?'violet':'grey'}
                        onClick={this.handleTypeChange}


                        />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field
                        control={Input}
                        label='From'
                        name='minprice'
                        labelPosition='right' 
                        type='text' 
                        placeholder='Amount' 
                        value = {numeral(this.state.searchterms.minprice).format('0,0')}
                        size='large'
                        onChange={this.handleChange}
                    >
                    <input />
                    <Label>UGX</Label>
                    </Form.Field>

                    <Form.Field
                    control={Input}
                        label='To'
                        name='maxprice'
                        labelPosition='right' 
                        type='text' 
                        value = {numeral(this.state.searchterms.maxprice).format('0,0')}
                        placeholder='Amount' 
                        size='large'
                        onChange={this.handleChange}
                    >
                    <input />
                    <Label>UGX</Label>
                    </Form.Field>
                </Form.Group>

                <input type="hidden" id="type" name="type" value={this.props.type} onChange={this.handleTypeChange}/>

                <Form.Group inline floated='right'>
                    <Form.Field 
                        color='violet' 
                        control={Button}  
                        icon='search' 
                        labelPosition='left'
                        content='Search'   
                        floated='right'
                        onClick={this.handleSubmit}               
                    />
                </Form.Group>
            </Form>
            </>
        )
    }
}

export default withRouter(HomeSearchPanel)