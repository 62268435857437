// Material UI components
import React, { Component } from 'react';
import PlotList from '../components/PlotList';
import ListOptions from '../components/ListOptions';

import {
    Container,
	Dimmer,
	Loader
  } from 'semantic-ui-react';

import SearchPanel from '../components/SearchPanel'  
import API from '../utils/api';
import * as ROUTES from '../constants/routes';

/*
  Input is an array of plots passed by props.plots
*/

class ShowPlotsPage extends Component {
	constructor(props){
		super(props);
		document.title = 'Afriplots - Properties';
		this.state = {
			//Initiate to empty array otherwise things will break if there is no array
			plots:[], 
			isLoading: true,
			// If we come from a Redirect we pick the search terms from there 
			// Otherwise we don't have any search terms and define {} so that params are valid in API call
			searchterms: (this.props.history.location.state)?this.props.history.location.state.searchterms:{},
		}
	}

  
	getPlots = (searchterms)=>{
		this.setState({isLoading:true});

		// Search for plots realted to search criteria
		// Store result in local state (we use these for the list) and the global state (WHY GLOBAL STATE)
		// Reset isLoading state to false
		API.get(ROUTES.PLOT,{params:{...searchterms}})
		.then(res => {
		  const plots = res.data;
		  // Store the plots in the global state 
		  this.props.storePlots({ plots });
		  // Store the plots in the local state
		  this.setState(
			  	()=>({plots}),
				()=>this.setState({isLoading:false})
			);  
		})
		.catch((error) =>{
			console.log(error);
		});
	}

	fireSubmit = (searchterms) =>{
		this.getPlots(searchterms);
	}

	componentDidMount(){
		this.getPlots(this.state.searchterms);
	}

	render() {
		const {plots,  isLoading, searchterms } = this.state;
		const {globalSetSearchTerms, starred_ids, isLoggedIn, handleStoreStars} =  this.props;

		return (
			<Container text style={{ marginTop: '4em' }}>
				{(!isLoading) 
				? 
					<>
					<SearchPanel 
							searchterms={searchterms} {...this.props} 
							globalSetSearchTerms={globalSetSearchTerms} 
							fireSubmit={this.fireSubmit}
							/>
					<ListOptions/>
					<PlotList 
							plots={plots} 
							starred_ids={starred_ids} 
							handleStoreStars={handleStoreStars} 
							isLoggedIn={isLoggedIn}
							/> 
					</>
				:
				<Dimmer active inverted>
					<Loader size='small'>Loading</Loader>
				</Dimmer> 
				}
			</Container>
		);
	}
}

export default ShowPlotsPage;