import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config={
    apiKey: "AIzaSyAX619hdFmFK1UZ4trRfruukNDWeCyhfk8",
    authDomain: "afriplots.firebaseapp.com",
    databaseURL: "https://afriplots.firebaseio.com",
    projectId: "afriplots",
    storageBucket: "afriplots.appspot.com",
    messagingSenderId: "172746560414",
    appId: "1:172746560414:web:4e91af005a497cc1653c8f"
};

const firebase = Firebase.initializeApp(config);
const { FieldValue } = Firebase.firestore;

export { firebase, FieldValue };


