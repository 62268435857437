import React from 'react';
import { withRouter } from "react-router";

import {
    Divider,
    Dropdown,
    Grid,
  } from 'semantic-ui-react'

class ListOptions extends React.Component{
    constructor(props){
        super(props);
        this.state={
            listoptions: {
                totalpages: 10,
                currentpage: 1,
                itemsperpage: 10,
                totalitems: 200,
                direction: 1,
                orderitem: 'price'
            },
            orderitems:[
                {key:'price', value:'price', text:'Price'},
                {key:'type', value:'type', text:'Plot type'},
                {key:'city', value:'city', text:'City'},
                {key:'area', value:'area', text:'Area'},
            ],
            directions:[
                { key: 'd', value: -1,  text: 'Desc'},
                { key: 'a', value: 1,  text: 'Asc' },
            ],
            pages:[
                { key: '0', value: '0',  text: '0' },
            ],
            searchterms: props.searchterms,
            fieldChanged: false
        }
    }

    onChange = (event,{name,value}) => {
        this.setState((prevState)=>({...prevState,listoptions:{...prevState.listoptions,[name]:value}}))
	};

    // Create page dropdown list based on the total number of pages
    createPages = (nrofpages)=>{
        let page = 1;
        let newpages = [];
        while (page <= nrofpages && nrofpages > 0){
              let item = {};
              item.key = `${page}`;
              item.value = page;
              item.text = `${page}`;
              newpages.push(item) 
              page++;
        }
        this.setState((prevState)=>({pages:newpages}))
    }

    componentDidMount(){
        this.createPages(this.state.listoptions.totalpages);
    }

    render(){
        const { currentpage, direction, totalpages,orderitem } = this.state.listoptions

    // console.log('-options-->',this.state.listoptions)
    // console.log('-search terms-->',this.state.searchterms)
        return (
            <>
            <Grid>
                <Grid.Row columns={2}>
                <Grid.Column>
                <b>Order by:</b>&nbsp;
                <Dropdown                         
                        compact
                        selection 
                        name='orderitem'
                        value={orderitem}
                        options={this.state.orderitems} 
                        onChange={this.onChange}
                /> 
                &nbsp;
                <Dropdown 
                        compact
                        selection 
                        name='direction'
                        value={direction}
                        options={this.state.directions} 
                        onChange={this.onChange}
                />                        
                </Grid.Column>

                <Grid.Column>
                &nbsp;
                &nbsp;
                <b>Page:</b>&nbsp;
                <Dropdown 
                        compact
                        name='currentpage'
                        selection 
                        value={currentpage}
                        options={this.state.pages} 
                        onChange={this.onChange}
                />    
                &nbsp;
                <b>of {totalpages}</b>
                </Grid.Column>
                </Grid.Row>
                </Grid>
                <Divider/>
            </>
        )
    }
}

export default withRouter(ListOptions);