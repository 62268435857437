// Material UI components
import React, { Component } from 'react';
import {Redirect, Link } from 'react-router-dom';

import {Container, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import API from '../utils/api'
import * as VARIOUS from '../constants/various';
import * as ROUTES from '../constants/routes';

class LoginPage extends Component {
	constructor(props) {
		super(props);

		document.title = 'Afriplots - Login';

		this.state = {
			email: '',
			password: '',
			errors: [],
			loading: false,
			validEmail: true,
			loginButton: false
		};
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		},
		()=>{
		// Test if email and password have 
		// TODO: add real checks for format
		if(this.state.email.length > 0 && this.state.password.length > 0){
			this.setState({loginButton: true});
		}
		else
		{
			this.setState({loginButton: false});
		} }
		);
	};


	handleSubmit = (event) => {
		// eslint-disable-next-line
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		event.preventDefault();
		this.setState({ loading: true });
		const userData = {
			email: this.state.email,
			password: this.state.password
		};
		if ( !re.test(userData.email) ) {
			this.setState({validEmail:false})
		}

		API.post(ROUTES.LOGIN, { ...userData })
		.then(res => {
			if(res.status === 200){
				const token = res.data['afri-token'];
				const id = res.data['id'];
				this.props.setUserLogin({email:this.state.email,afritoken:token,id:id});
				//TODO; check timing async
				this.props.getUserData(token);

			}
			else
			{
				this.setState({validEmail:false});
			}
      	})
		.catch((error) =>{
			this.setState({validEmail:false});
		});
	};

	render() {

		return (
			(this.props.user.isLoggedIn)?
			<>
				<Container text style={{ marginTop: '5em' }}>
				<Header as='h2'>
					Logged in
				</Header>
				<p>
					You are logged in already.
					<Redirect to={ROUTES.HOME} />
				</p>
				</Container>
			</>
			:
			<>
				<Grid textAlign='center' style={{ height: '550px' }} verticalAlign='middle'>
					<Grid.Column style={{ maxWidth: 400 }}>
					<Header as='h2' color='black' textAlign='center'>
						<Image src={VARIOUS.AP_LOGO_BLACK} /> Log-in to your account
					</Header>
					<Form size='large'>
						<Segment stacked>
						<Form.Input 
							fluid
							id='signup-form-email' 
							icon='at' 
							iconPosition='left' 
							placeholder='E-mail address' 
							name='email'
							onChange={this.handleChange}
						/>
						<Form.Input
							fluid
							id='login-form-password'
							icon='lock'
							iconPosition='left'
							placeholder='Password'
							type='password'
							name='password'
							onChange={this.handleChange}
						/>
					
						<Button disabled={!this.state.loginButton} color='violet' fluid size='large' onClick={this.handleSubmit}>
							Login
						</Button>		
						</Segment>
					</Form>
					<Message>
						New to us? <Link to={ROUTES.SIGNUP}>Sign Up</Link>
					</Message>
					{(!this.state.validEmail)
					?
					<Message
						error
						header='Error'
						content='You entered a wrong e-mail address and/or password.'
					/>
					:
					''
					}
					</Grid.Column>
				</Grid>
			</>
		);
	}
}

export default LoginPage;