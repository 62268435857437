import React from 'react';
import { Link } from 'react-router-dom'
import {
    Container,
    Dropdown,
    Image,
    Menu
  } from 'semantic-ui-react'

  import * as ROUTES from '../constants/routes';
  import * as VARIOUS from '../constants/various';


const logo = VARIOUS.AP_LOGO_WHITE;
const title = 'Afriplots'

class FixedHeader extends React.Component{
    constructor(props){
        super(props);
        this.state={
            countryOptions: [
                { key: 'ug', value: 'Uganda', flag: 'ug', text: 'Uganda' },
              ]
        }
    }

    dropDownItem = (link, text, show) =>{

        if(show){
            return <Dropdown.Item as={Link} to={link}>{text}</Dropdown.Item>
        }else{
            return <Dropdown.Item disabled as={Link} to={link}>{text}</Dropdown.Item>    
        }
    }

    render(){
        const showStarredMenuItem = (this.props.user.starred_ids.length>0)

        return (
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item as={Link} to={ROUTES.HOME} header>
                        <Image size='mini' src={logo} style={{ marginRight: '1.5em' }} />
                            {title}
                        </Menu.Item>
                        <Menu.Item as={Link} to={ROUTES.SHOWPLOTS}>Plots</Menu.Item>
                        <Menu.Menu position='right'>
                        <Dropdown 
                            item 
                            text='Uganda'    
                            icon='globe'                        
                        >
                            <Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>

                        {(!this.props.user.isLoggedIn)?
                            <Menu.Item as={Link} to={ROUTES.LOGIN}>Login</Menu.Item>
                            :
                            <Dropdown item simple text='User'>
                            <Dropdown.Menu>
                                { this.dropDownItem(ROUTES.STARREDPLOTS,'Starred plots', showStarredMenuItem)}
                                { (this.props.user.broker_active)?<Dropdown.Divider />:''}
                                { (this.props.user.broker_active)?this.dropDownItem(ROUTES.ADDPLOT,'Add plot',true):''}
                                { (this.props.user.broker_active)?this.dropDownItem(ROUTES.OWNPLOTS+'/'+this.props.user._id,'Own plots',true):''}
                                <Dropdown.Divider />
                                { this.dropDownItem(ROUTES.PROFILE,'Profile',true)}
                                { this.dropDownItem(ROUTES.LOGOUT,'Logout',true)}
                            </Dropdown.Menu>
                            </Dropdown>
                        }
                        </Menu.Menu>
                </Container>
            </Menu>
        )
    }
}

export default FixedHeader;