import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 35 35"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path fill={fill} d="M16,0C7.2,0,0,7.2,0,16c0,8.8,7.2,16,16,16s16-7.2,16-16C32,7.2,24.8,0,16,0z M17.2,19.6
	c-1,0-1.9-0.5-2.2-1.1c0,0-0.5,2.1-0.6,2.5c-0.4,1.4-1.6,2.9-1.7,3c-0.1,0.1-0.2,0.1-0.2-0.1c0-0.2-0.3-2,0-3.5c0.2-0.7,1.2-5,1.2-5
	s-0.3-0.6-0.3-1.5c0-1.4,0.8-2.4,1.8-2.4c0.8,0,1.2,0.6,1.2,1.4c0,0.8-0.5,2.1-0.8,3.3c-0.2,1,0.5,1.8,1.5,1.8
	c1.7,0,2.9-2.2,2.9-4.9c0-2-1.4-3.5-3.8-3.5c-2.8,0-4.5,2.1-4.5,4.4c0,0.8,0.2,1.4,0.6,1.8c0.2,0.2,0.2,0.3,0.1,0.5
	c0,0.2-0.1,0.6-0.2,0.7c-0.1,0.2-0.2,0.3-0.5,0.2c-1.3-0.5-1.9-1.9-1.9-3.5C9.8,11.1,12,8,16.4,8c3.5,0,5.8,2.5,5.8,5.3
	C22.2,16.9,20.2,19.6,17.2,19.6z"/>
  </svg>
);

export default SVG;
