// Material UI components
import React from 'react';
import PlotListRow from '../components/PlotListRow';

import {
    Item,
    Message,
  } from 'semantic-ui-react'


/*
  Input is an array of plots passed by props.plots
*/

class  PlotList extends React.Component{
    
  render(){

    const {plots, starred_ids, isLoggedIn, handleStoreStars} = this.props

    return (
           
            (plots.length)?
            <>
            <Item.Group divided>
            {
                plots.map(
                    plot => (<PlotListRow 
                                key={plot._id} 
                                plot={plot} 
                                starred_ids={starred_ids} 
                                handleStoreStars={handleStoreStars} 
                                isLoggedIn={isLoggedIn} 
                                />)
                )
            }
            </Item.Group>
            </>
            :
            <Message negative
              icon='frown' 
              header="We're sorry there are no properties with these characteristics"
              content="Please change some of the search riteria."
            />
     )
  }
}
export default PlotList;