import React from 'react';

class ErrorBoundary extends React.Component
{
    constructor(props){
        super(props);
        this.state ={
            hasErrored: false
        }
    }

    static getDerivedStateFromError(error){
        return {hasErrored:true}
    }

    componentDidCatch(error,info){
        console.log(error);
    }

    render(){
        if(this.state.hasErrored===true){
            return <div>Something went wrong</div>
        }

        return this.props.children
    }
}

export default ErrorBoundary;